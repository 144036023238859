<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/banner/list' }">首页幻灯</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" class="el-form-self">

        <el-row>
          <el-col>
            <el-form-item label="类型:" prop="type">
              <div class="view-text">{{form.type==='1'?'首页幻灯':form.type==='2'?'服务幻灯':''}}</div>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="name">
              <div class="view-text">{{form.bannerName}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="序号:" prop="numberOrder">
              <div class="view-text">{{form.numberOrder}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="幻灯图片:" prop="imageUrl" :disabled=true>
              <el-upload
                class="companyNewsImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false">
                <img v-if="imageUrl" :src="imageUrl" class="homeBannerImage">
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="toEdit" >编辑</el-button>
            <el-button type="primary" @click="unStartUse" v-if="form.isUse==='1'">下架</el-button>
            <el-button type="primary" @click="startUse" v-if="form.isUse==='2'">启用</el-button>
            <el-button type="primary" @click="$router.push({path:'/banner/list',query:{filtersName:$route.query.filtersName}})">返回</el-button>
          </el-form-item>
        </el-row>
      </el-form>


    </el-col>
  </el-row>

</template>
<script>
  import {uploadFileURL,downloadFileURL} from '../../../api/system-api'
  import {reqGetBannerOne,reqEditStatusBanner}   from '../../../api/banner-api'
  export default {
    data() {
      return {
        filtersName:'',
        editPath:'/banner/list',
        uploadFileURL:uploadFileURL,
        imageUrl: '',
          typeArray:[
              {text:'首页幻灯',value:'1'},
              {text:'静态广告',value:'2'},
          ],
        form: {
          id: 0,
          bannerName: '',
          content: '',
          numberOrder: '',
          isUse:'',
          type: '',
          imageUrl: '',
        },
      }
    },
    methods: {
      toEdit(){

      this.$router.push({path: "/banner/edit?id="+this.form.id});

    },
          unStartUse(){
            var that=this;
            this.form.isUse='2';
            let para = Object.assign({},  this.form);
              reqEditStatusBanner(para).then(() => {
              that.$message({
                message: '下架成功',
                type: 'success'
              });
              that.$refs.form.resetFields();
              that.$router.push({path: that.editPath});
            });
         },

          startUse(){
            var that=this;
            this.form.isUse='1';
            let para = Object.assign({},  this.form);
              reqEditStatusBanner(para).then(() => {
              that.$message({
                message: '启用成功',
                type: 'success'
              });
              that.$refs.form.resetFields();
              that.$router.push({path: that.editPath});
            });

          },

      getBanner(id) {
        var that = this;
        reqGetBannerOne({id: id}).then((res) => {
          if(res.data.data.imageUrl){
            this.imageUrl=downloadFileURL + res.data.data.imageUrl + "/";
          }else {
            this.imageUrl='';
          }
          that.form = Object.assign({}, res.data.data);

        });
      },
    },
    mounted() {
      let id = this.$route.query.id;
      this.getBanner(id);
    }
  }
</script>
